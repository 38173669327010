/* eslint-disable */
// prettier-ignore
export default {
  currency: [
    {
      id: 1,
      name: "Vietnamese đồng",
      symbol: "₫",
      iso_code: "VND",
      img: require('@/assets/images/flags/vi.png'),
    },
    {
      id: 2,
      name: "Dollar",
      symbol: "$",
      iso_code: "USD",
      img: require('@/assets/images/flags/en.png'),
    },
    {
      id: 3,
      name: "Cambodian riel",
      symbol: "៛",
      iso_code: "KHR",
      img: require('@/assets/images/flags/km.jpg'),
    }
  ]
}
/* eslint-enable */
