<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          Buso
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            src="@/assets/images/pages/login-v2.svg"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            {{ $t('Welcome to Buso!') }} 👋
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('Please select your site and start the adventure') }}
          </b-card-text>

          <!-- form -->
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="selectSite"
          >
            <!-- site -->
            <b-form-group>
              <v-select
                v-model="selectedSite"
                label="name"
                :options="option"
              />
            </b-form-group>

            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="primary"
              block
            >
              {{ $t('Continue') }}
            </b-button>
          </b-form>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable prefer-destructuring */

import VuexyLogo from '@core/layouts/components/Logo.vue'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'

import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import languageJson from '@/libs/lang/lang'
import currencyJson from '@/libs/currency/currency'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    vSelect,
  },
  mixins: [general],
  data() {
    return {
      // Site
      selectedSite: null,
      option: [],
      // Language
      languageDefault: {
        name: null,
        locale: null,
        img: null,
        is_active: true,
      },
      // Currency
      currencyDefault: {
        name: null,
        display_name: null,
        symbol: null,
        iso_code: null,
        img: null,
        is_active: true,
      },
      setCurr: false,
      setLang: false,
    }
  },
  mounted() {
    this.loadSite()
  },
  methods: {
    async selectSite() {
      if (this.selectedSite) {
        // await this.setLanguage(this.selectedSite)
        // await this.setCurrency(this.selectedSite)
        // if (this.setCurr) {
        this.selectedSite.currency = this.selectedSite.currency !== null ? this.selectedSite.currency : 'VND'
        this.$i18n.locale = this.selectedSite.language
        localStorage.setItem('siteID', JSON.stringify(this.selectedSite))
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/setting/custom_data_site?site_id=${
            this.selectedSite.id
          }`,
        )
        if (res.data.status) {
          localStorage.setItem('customDataSite', JSON.stringify(res.data.data.value))
        }
        this.$router.push({ name: 'dashboard-homepage-v1' })
        // }
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String('Please choose the site'),
          },
        })
      }
    },
    async loadSite() {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/sites?user_id=${
            JSON.parse(localStorage.getItem('token')).id
          }`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.option = res.data.data.items
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async setCurrency(site) {
      let currCurrency = null
      const listCurrency = await this.loadcurrency(site.id)
      const curr = site.currency !== null ? site.currency : 'VND'
      currCurrency = currencyJson.currency.filter(
        x => x.iso_code === curr,
      )
      currCurrency[0].is_active = true
      this.currencyDefault = currCurrency[0]

      const findDefaultCurrency = listCurrency.find(
        x => x.iso_code === currCurrency[0].iso_code,
      )
      if (findDefaultCurrency === undefined) {
        this.setCurr = false
        const res = await Request.post(
          this.$http,
          `${process.env.VUE_APP_API_URL}/currency`,
          {
            site_id: site.id,
            name: currCurrency[0].name,
            code: currCurrency[0].iso_code,
            exchange: 1,
            is_active: Number(currCurrency[0].is_active === true ? 1 : 0),
            img: 'null',
          },
        )
        if (res) {
          if (res.data.status) {
            this.setCurr = true
          } else {
            this.setCurr = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      }
      this.setCurr = true
    },
    async loadcurrency(siteID) {
      this.isShow = true
      const currencyOptions = []
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/currencies?site_id=${
            siteID
          }`,
        )
        if (res.status === 200) {
          this.isShow = false
          if (res.data.status) {
            res.data.data.items.map(val => {
              const currencyFil = currencyJson.currency.filter(
                x => x.iso_code === val.code,
              )
              currencyFil[0].is_active = Boolean(val.is_active === 1)
              currencyFil[0].id = val.id
              return currencyOptions.push(currencyFil[0])
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return currencyOptions
    },
    async setLanguage(site) {
      let currLang = null
      const listLang = await this.loadLanguage(site.id)
      currLang = languageJson.language.filter(
        val => val.locale === site.language,
      )
      currLang[0].is_active = true
      this.languageDefault = currLang[0]

      const findDefaultLang = listLang.find(
        x => x.locale === currLang[0].locale,
      )
      if (findDefaultLang === undefined) {
        await Request.post(
          this.$http,
          `${process.env.VUE_APP_API_URL}/language`,
          {
            site_id: site.id,
            name: currLang[0].name,
            locale: currLang[0].locale,
            is_active: Number(currLang[0].is_active === true ? 1 : 0),
            img: 'null',
          },
        )
      }
    },
    async loadLanguage(siteID) {
      this.isShow = true
      const languageOptions = []
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/languages?site_id=${
            siteID
          }`,
        )
        if (res.status === 200) {
          this.isShow = false
          if (res.data.status) {
            res.data.data.items.map(val => {
              const langFil = languageJson.language.filter(
                lang => lang.locale === val.locale,
              )
              langFil[0].is_active = Boolean(val.is_active === 1)
              langFil[0].id = val.id
              return languageOptions.push(langFil[0])
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return languageOptions
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
